.Container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    /* padding: 0 1rem; */
    background-color: #fff;
}

.Container::-webkit-scrollbar {
    display: none;
}

.Header {
    display: flex;
    justify-content: space-between;
    background-color: #a29f9f;
    align-items: center;
    color: #fff;
    height: 4rem;
    border-radius: 10px;
    margin: 1rem;
}

.HeaderText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
}

.HeaderText p {
    margin: 0;
    font-size: 1.5rem;
}

.passwordContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30rem;
    height: 20rem;
    border: 1px solid #958787;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

input {
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    border-radius: 8px;
    outline: none;
    border: 1px solid #c2adad;
    margin-bottom: 2.25rem;
    height: 2.75rem;

}

button {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #a29f9f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
}

button:hover {
    background-color: #8c8b8b;
}
.bottomContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
}
.newPasswrdEyeIcon {
    position: absolute;
    right: 2rem; 
    top:26%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #a29f9f; 
    z-index: 1;
}
.confirmPasswrdEyeIcon {
    position: absolute;
    right: 2rem; 
    top: 52%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #a29f9f; 
    z-index: 1;
}
