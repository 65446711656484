.Container{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 1rem;
    background-color: rgb(241, 239, 239);
}
.Container::-webkit-scrollbar{
    display: none;
}
.Header {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1a1e5d;
    align-items: center;
    margin-top: 1rem;
    color: #fff;
    height: 4rem;
    border-radius: 10px;
  }
  .HeaderText {
    width: 100%;
    margin-left: 1rem;
  }
  .HeaderText p {
    font-size: 1.25rem;
  }
  .List {
    width: 100%;
    margin-top: 2rem;
  }
  .table {
    width: 100%;
    border-spacing: 0;
  }
  .table tr {
    text-align: center;
  }
  /* th {
      background-color: #28309e;
    width: 10rem;
    height: 3rem;
    color: #fff;
  }
  td {
    border: none;
    height: 2.5rem;
    border-bottom: 1px solid #dbdbdb;
  } */
  .Buttons{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
  .Buttons button{
    font-size: 1rem;
    outline: none;
    border-radius: 5px;
    border: none;
    width: 4rem;
    padding: 4px 0;
    border: 1px solid;
    margin:0 .5rem ;
  }
  .passwordInput{
    border: none;
    background-color: transparent;
    /* width: 100%; */
    text-align: center;
    font-size: 1rem;
    outline: none;
  }
  .eye{
    font-size: 1.25rem;

  }
  
  