.Container {
  height: 100vh;
  width: 100%;
  background: url("https://static.zohocdn.com/iam/v2/components/images/bg.49756b7c711696d95133fa95451f8e13.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.loginPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 55vh;
  background: #fff;
  box-shadow: 0 3px 8px #0000001f;
}
.pageLeft {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  border-right: 2px solid #f1f1f1;
  box-sizing: border-box;
}
.leftPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.headerLogo {
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLogo img {
  width: 100%;
  height: 100%;
  filter: invert(100%);
}
.headerText {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerText p {
  font-size: 1.15rem;
  font-weight: 600;
  color: #cda653;
}
.leftPageCentre {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-evenly;
  border-bottom: 2px solid #f1f1f1;
  height: 65%;
}
.signIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.signIn h3 {
  font-size: 1.35rem;
}
.signIn p {
  font-weight: 550;
}
.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.input input {
  width: 70%;
  padding: 0.5rem;
  outline: none;
  background: #f8f8f8;
  border: 1px solid #e4e4e4;
  font-size: 1rem;
  text-align: start;
  font-weight: 700;
  color: #cda653;
}
.input input:nth-child(2) {
  margin: 0.5rem 0;
}
.input input::placeholder {
  color: #939393;
}
.input button {
  width: 70%;
  margin: 0.75rem 0;
  padding: 0.5rem;
  font-size: 1.15rem;
  border: 1px solid #f3bc46;
  background: #cda653;
  color: #fff;
  font-weight: 600;
}

.leftPageBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
}
.bottomHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
}
.bottomHeader p {
  font-size: 1.15rem;
  font-weight: 600;
  color: #191930;
}
.iconSet {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.iconSet svg {
  font-size: 1.25rem;
}
.iconSet svg:nth-child(2) {
  margin: 0 0.5rem;
}
.pageRight {
  display: flex;
  align-items: center;
  width: 35%;
  height: 100%;
  justify-content: center;
  padding: 1rem;
}
.sliderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.Slider {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.SliderNav {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CircleNav .Active {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  background-color: blue;
  border: 3px solid #fff;
}
.CircleNav .NonActive {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 3px solid #fff;
}
@media (max-width: 480px) {
  .loginPage {
    width: 95%;
    height: auto;
  }
  .pageLeft {
    width: 100%;
    border-right: 0px;
    padding: 1.5rem 1rem;
  }
  .pageRight {
    display: none;
  }
  .leftPageHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .headerLogo {
    width: 3.5rem;
  }
  .headerText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
  }
  .headerText p {
    font-size: 1rem;
  }
  .leftPageCentre {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    border-bottom: 2px solid #f1f1f1;
    height: auto;
    margin: 0.5rem 0;
  }
  .signIn h3 {
    font-size: 1.25rem;
  }
  .input {
    padding: 0.5rem 0;
  }
  .input input {
    width: 100%;
  }
  .input button {
    width: 100%;
    margin: 0.5rem 0;
  }
  .bottomHeader p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .loginPage {
    width: 95%;
    height: auto;
  }
  .pageLeft {
    width: 100%;
    border-right: 0px;
    padding: 1.5rem 1rem;
  }
  .pageRight {
    display: none;
  }
  .headerLogo {
    width: 6rem;
  }
  .headerText p {
    font-size: 1.35rem;
  }
  .leftPageCentre {
    height: auto;
    margin: 1rem 0;
  }
  .signIn h3 {
    font-size: 2rem;
  }
  .signIn p {
    font-size: 1.5rem;
  }
  .input {
    margin: 0.75rem 0;
  }
  .input input {
    width: 90%;
    padding: 0.75rem 0.35rem;
    font-size: 1.5rem;
  }
  .input button {
    width: 90%;
    font-size: 1.75rem;
  }
  .bottomHeader p {
    font-size: 1.5rem;
  }
  .iconSet {
    margin: 0.25rem 0;
    padding: 0.25rem 0;
  }
  .iconSet svg {
    font-size: 1.75rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .loginPage {
    width: 95%;
  }
  .pageLeft {
    width: 50%;
  }
  .leftPageHeader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .headerText {
    padding: 0.5rem 0;
  }
  .headerText p {
    font-size: 1.35rem;
  }
  .leftPageCentre {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: space-evenly;
    border-bottom: 2px solid #f1f1f1;
    height: 60%;
  }
  .signIn h3 {
    font-size: 1.75rem;
  }
  .signIn p {
    font-size: 1.25rem;
  }
  .input input {
    width: 100%;
    font-size: 1.15rem;
  }
  .input button {
    width: 100%;
    font-size: 1.15rem;
  }
  .bottomHeader p {
    font-size: 1.35rem;
  }
  .iconSet {
    padding: 0.25rem 0;
  }
  .iconSet svg {
    font-size: 1.5rem;
  }
  .pageRight {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    justify-content: center;
    padding: 0.5rem;
  }
}
/* @media (min-width: 1025px) and (max-width: 1280px) {
  body {
    background-color: green;
  }
} */
