.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* padding: 0 1rem; */
  background-color: #fff;
}
.Container::-webkit-scrollbar {
  display: none;
}
.Header {
  /* width: 100%; */

  display: flex;
  justify-content: space-between;
  background-color: #a29f9f;
  align-items: center;
  color: #fff;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
}
.HeaderText {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.HeaderText p {
  margin: 0;
  font-size: 1.5rem;
}
.HeadButton {
  width: 15%;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  align-items: center;
  padding: 1rem;
}
.orgSection {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  /* height: 100%; */
  padding: 0 1rem;
  /* /* width: 100%;  */
}
.HeadButton button {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
.mapButton button{
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 11rem;
  cursor: pointer;
}
.Grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Cards {
  display: flex;
  background: #fff;
  /* width: 20rem; */
  /* align-items: center; */
  /* justify-content: space-between; */
  border-radius: 1.25rem;
  border: 2px solid #a39797;
  outline: none;
  padding: 0.3rem 0.5rem;
  height: 8rem;
  cursor: pointer;
}
.CardLeft{
  width:30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CardRight{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}
.Cards p {
  font-size: 1.25rem;
  color: #857073;
  /* cursor: pointer;
  transition: color 0.3s, background-color 0.3s; */
}
/* .Cards p:hover {
  color:#66cc00; 
} */
.cardTop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.cardBottom {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  /* padding: 1rem; */
  height: 50%;
}
.viewButton {
  color: #b4b409;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}
.iconContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipText {
  visibility: hidden;
  width: 75px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px; */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1rem;
}

.iconContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
.cardContent {
  width: 100%;
  align-items: center;
  margin-left: 2rem;
}
.cardContent p {
  font-size: 1.25rem;
  font-weight: 500;
}
.AddressSection {
  width: 40rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 1rem;
  padding: 1.25rem;
  align-items: center;
}
.modalDropSec {
  width: 27rem;
  position: absolute;
  top: 40%;
  left: 55%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 1rem;
  padding: .5rem;
  align-items: center;
}

.popupClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.popupSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  /* align-items: center; */
  justify-content: center;
  height: 70%;
}
.popupSection input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: left;
  border-radius: 5px;
  margin: 0.3rem 0;
  border: 2px solid #696969c1;
  outline: none;
  font-weight: 700;
}
.popupSection input:focus {
  outline: 3px solid #606e7a;
  border-color: transparent;
}
.popupSection select{
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.3rem 0;
  border: 2px solid #696969c1;
  outline: none;
  font-weight: 700;
}
.popupButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 30%;
}
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #49618b;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  padding: 0.5rem;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #7e3e3e;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  padding: 0.5rem;
}
.popupSection input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
}

.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.deletePopup {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f6f5f5;
  border-radius: 1rem;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
  color: #121111;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  font-size: 1.25rem;
  color: #2e2d2d;
  text-align: center;
}
.popupText p>span{
color: #7e3e3e;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
  margin: 1rem 0;
}
.orgDelete {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
.orgDeleteCancel {
  background: #7e3e3e;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
.LogoImage img{
  width: 100%;
  height: 100%;
}
.ProfilePopup{
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  z-index: 9999;

}
.ProfilePopup form {
  background-color: #efefef;
  padding: 1rem 2rem ;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
  justify-content: center;
}
.ProfilePopup form input{
  /* width: 100%; */
  font-size: 1rem;
  background-color:transparent;
  padding: 10px 1px;
  text-align: center;
  border-radius:1px ;
  margin: 1px 0;
  border:none;
  outline: none;
}
.Formtext{
  width: 100%;
}
.Formtext p{
  margin: 4px 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.ProfileOverlay{
  width: 100vw;
height: 100vh;
position: absolute;
background-color:rgba(0, 0, 0, 0.4);
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 9;
}
.FormButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
}
.ProfilePopup form button {
  border-radius: 6px;
  background-color: #1a1e5d;
  border: none;
  outline: none;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 1rem;
  /* text-align: center; */
  padding:0 4rem;
  cursor: pointer;
}
.InputContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}
.InputLabel{
  width: 15rem;
}
.Input{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Input input{
  width: 100%;
  outline: none;
  border: 2px solid #dbdbdb;
  border-radius: 6px;
  padding: 4px;
  height: 3rem;
}
.Input label{
  width: 15%;
}
.Input button{
  background-color: cornflowerblue;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.1rem;
}
.PartnerCompany{
  max-height: 13rem;
  overflow-y: scroll;
}
.PartnerCompany table{
  width: 100%;
  border-spacing: 0;
  /* border: 2px solid #a8b1b9; */
  border-radius: 5px;
}
.PartnerCompany tbody tr {
  text-align: center;
  height: 2rem;
}
.PartnerCompany td {
  padding: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
font-size: 1.15rem;
text-align: center;
}
.PartnerCompany td input{
  width: 100%;
  outline: none;
  border: 2px solid #dbdbdb;
  border-radius: 6px;
  padding: 4px;
  height: 2.8rem;
  font-size: 1rem;
}
.PartnerCompany td:nth-child(3){
  width: 6rem;
}
.PartnerCompany td button{
  background-color: rgb(227, 74, 71);
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 6px 8px;
  font-size: 1rem;
}

/* ------------------------- */
.selection{
  display: flex;
  align-items: center;
  gap: 10px;
}

.partnerLabel{
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.partnerSelect{
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.partnerSelect:focus{
  border-color: #66afe9;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
  outline: none;
}
.partnerSelect option{
  padding: 10px;

}
.userManagement {
  display: flex;
  flex-direction: column;
  padding: .5rem 0;
  align-items: flex-start;
  align-items: center;
}
.List {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  margin: 0rem;
  padding: 1rem;
  row-gap: 1rem;
}
.userCards {
  display: flex;
  width: 22rem;
  height: 10rem;
  align-items: flex-start;
  justify-content: center;
  border: 2px solid #a4840d;
  padding: 1rem;
  border-radius: 15px;
  flex-direction: column;
  cursor: pointer;
}
.userCardTop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 75%;
}
.userDetail {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: flex-start;
  justify-content: center;
}
.userDetail h6 {
  font-size: 1.25rem;
  color: #6a2ba7;
  margin: 0;
}
.userDetail p {
  font-size: 1.25rem;
  color: #415b72;
  font-weight: bold;
}
.userDetail span {
  color: #06481a;
}

.userImage {
  font-size: 2rem;
  color: #a52a60;
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
}
.cardBotton {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.cardBotton button {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
